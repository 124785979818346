import * as React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { EpisodeCard, SEO } from "../components";

const IndexPage: React.FC = ({ data }) => {
  const episodes = data.allFeedEpisodes.edges.map((a) => a.node);
  return (
    <>
      <SEO title="Homepage" />
      <main>
        <section tw="bg-hm-light relative text-gray-500 w-full py-8">
          <StaticImage
            alt="Hobgob and Mulch banner"
            src="../images/hm_banner_transparent_bg.png"
            loading="eager"
            layout="fullWidth"
            tw="h-full max-w-screen-lg mx-auto"
          />
        </section>
        <section tw="max-w-screen-lg mx-auto font-ringbearer text-center px-2">
          <h1 tw="text-5xl lg:text-6xl leading-normal my-16">
            {"The Tales of Hobgob & Mulch"}
          </h1>
          <h2 tw="mb-3 text-2xl text-gray-700">
            {"Welcome to the Tales of Hobgob & Mulch!"}
          </h2>
          <h2 tw="mb-3 text-2xl text-gray-700">
            {"Fairy tales, folk tales, adventure!"}
          </h2>
          <h2 tw="mb-3 text-2xl text-gray-700">
            {"Short story audiobooks for all the family"}
          </h2>
        </section>
        <section tw="max-w-screen-lg mx-auto" id="episodes">
          <h3 tw="mt-16 font-ringbearer text-4xl underline text-center">
            Episodes
          </h3>
          <div>
            {episodes
              .sort(
                (a, b) =>
                  a.itunes.season.localeCompare(b.itunes.season) ||
                  a.itunes.episode.localeCompare(b.itunes.episode)
              )
              .map((a) => (
                <EpisodeCard key={a.title} {...a} />
              ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    allFeedEpisodes {
      edges {
        node {
          title
          itunes {
            episode
            season
          }
          enclosure {
            url
          }
          content {
            encoded
          }
        }
      }
    }
  }
`;
